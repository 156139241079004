<template>
  <div class="page">
    <div class="top flex_align"><span class="text">选择楼层</span></div>
    <div class="list" v-for="item in floor" :key="item.id">
      <div class="floor flex_between" @click="toggle(item)">
        <div class="v_b">
          <div class="num">{{ item.title }}</div>
          <div class="type flex_align type4">{{ item.total }}间</div>
        </div>
        <div class="free v_b">
          剩余{{ item.free }}个床位
        </div>
      </div>
      <div class="room flex_column" :class="{ 'room_open': item.open }">
        <transition name="fade">
          <div class="room_container flex_wrap flex_up" v-show="item.open">
            <div class="room_tag flex_align" v-for="r in item.room" :key="`${item.id}${r.id}`" @click="go(r)">
              <div class="left flex_center" :class="{ 'left_busy': r.free === 0 }">{{ r.free === 0 ? '满' : `余${r.free}` }}</div>
              <div class="right">{{ r.label }}</div>
            </div>
          </div>
        </transition>
        <div class="toggle" @click="toggle(item)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFloor, getFloorBedroom } from '@/api/dormitory'

export default {
  props: {
    area: {}
  },
  beforeRouteEnter (to, from, next) {
    if (from.name === 'roomStatus') {
      next()
    } else {
      next(vm => {
        vm.getFloor()
      })
    }
  },
  name: 'areaStatus',
  data () {
    return {
      floor: [
        { id: 1, lou: '1', title: '一层', total: 20, free: 4, open: false, room: [] }
        // { id: 2, title: '二层', total: 20, free: 4, open: false, room: [] },
        // { id: 3, title: '三层', total: 20, free: 4, open: false, room: [] },
        // { id: 4, title: '四层', total: 20, free: 4, open: false, room: [] }
      ]
    }
  },
  created () {
    this.getFloor()
  },
  methods: {
    getFloor () {
      const data = {
        apartmentId: this.area
      }
      this.floor = []
      getFloor(data).then(res => {
        const { data: r } = res
        const { data, msg, code } = r
        if (code === 0) {
          this.floor = data.map(item => {
            const { floor, num: total, bedNum: free, lou } = item
            return { room: [], open: false, id: floor, total, free, title: `${floor}层`, lou }
          })
        } else {
          this.$toast.fail(msg)
        }
      })
    },
    go (item) {
      this.$router.push({ name: 'roomStatus', params: { bedroomId: item.id, roomName: item.label } })
    },
    toggle (item) {
      if (item.open === false) {
        if (item.room.length === 0) {
          const data = {
            apartmentId: this.area,
            lou: item.lou,
            floor: item.id
          }
          getFloorBedroom(data).then(res => {
            const { data: r } = res
            const { data, msg, code } = r
            if (code === 0) {
              item.room = data.map(item => {
                const { id, name: label, num: free } = item
                return { id, label, free }
              })
              console.log(item.room)
            } else {
              this.$toast.fail(msg)
            }
          })
          // for (let i = 0; i < 20; i++) {
          //   item.room.push({ id: i, label: 201, free: 0 })
          // }
        }
        item.open = true
      } else {
        item.open = false
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/transition";
.page {
  background: #F3F3F3;
}
.top {
  height: 25px;
  padding: 15px 40px;
  .text {
    font-size: 14px;
    color: #000;
    position: relative;
    &:before {
      content: '';
      width: 4px; height: 19px;
      background: #75AFFE;
      border-radius: 1px;
      position: absolute;
      left: -20px; top: 50%;
      transform: translateY(-50%);
    }
  }
}
.list {
  margin: 0 20px 12px;
  .floor {
    width: 295px;
    padding: 10px 20px 15px 20px;
    background: #FFF;
    box-shadow: 0 3px 5px rgba(187, 214, 251, 0.16);
    border-radius: 12px;
    .v_b {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    .free {
      color: #75AFFE;
      font-size: 12px;
    }
    .num {
      color: #75AFFE;
      font-size: 30px;
      margin-right: 15px;
      transform: translateY(4px);
    }
    .type {
      padding: 2px 6px;
      font-size: 9px;
      border-radius: 4px;
      &.type4 {
        color: #FFF;
        background: #1CB394;
      }
      &.type2 {
        color: #FFF;
        background: #85A2FB;
      }
    }
  }
  .room {
    height: 0;
    padding-bottom: 16px;
    margin: 0 6px;
    background: #E7EFFB;
    box-shadow: 0 3px 5px rgba(173, 207, 254, 0.11);
    border-radius: 12px;
    position: relative;
    transition: height .23s;
    overflow: hidden;
    .room_container {
      padding: 20px 20px 6px;
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: auto;
    }
    .room_tag {
      width: 81px; height: 32px;
      background: #FFF;
      border-radius: 9px;
      box-shadow: 0 3px 5px rgba(133, 164, 236, 0.16);
      margin-bottom: 10px;
      &:not(:nth-child(3n)) {
        margin-right: 20px;
      }
      .left {
        width: 26px; height: 18px;
        font-size: 11px;
        color: #FFF;
        background: #559BFC;
        border-radius: 6px;
        margin-right: 6px;
        margin-left: 4px;
        &.left_busy {
          background: #1CB394;
        }
      }
      .right {
        font-size: 12px;
        color: #636363;
      }
    }
    .toggle {
      width: 100%; height: 16px;
      position: absolute;
      left: 50%; bottom: 4px;
      transform: translateX(-50%);
      &:before {
        content: '';
        width: 0; height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #559BFC;
        position: absolute;
        left: 50%; bottom: 0;
        transform: translateX(-50%);
      }
    }
    &.room_open {
      height: 180px;
      overflow: hidden auto;
      .toggle {
        &:before {
          transform: translateX(-50%) rotateX(180deg);
        }
      }
    }
  }
}
</style>
